@use "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #008200;
$nav-tabs-border-width: 2px;
$font-sans-pro: 'Source Sans Pro', sans-serif;

.font-sans-pro, a:link, h1, h2, h3, h4, h5, h6 {
  font-family: $font-sans-pro !important;
}

.bg-light-grey {
  background-color: #f0f1f2 !important;
}

.bg-dark-blue {
  background-color: #003349 !important;
}

.bg-light-blue {
  background-color: #0077cb !important;
}

.bg-dark-green {
  background-color: #008200 !important;
}

.linkNoUnderline {
  text-decoration: none;
}

.linkNoUnderline, a
{
    text-decoration:none !important;
}

.link-color-grey, a {
  color: rgba(black, .75) !important;
}

/* Navigation */

.nav-link.active {
  animation-duration: 1s;
  animation-name: navActive;
}

.nav-link {
    animation-duration: 1s;
    animation-name: navInactive;
}

@keyframes navActive {
  from {
    border-color: transparent;
  }

  to {
    border-color: $nav-tabs-link-active-border-color;
  }
}


@keyframes navInactive {
  to {
    border-color: transparent;
  }

  from {
    border-color: $nav-tabs-link-active-border-color;
  }
}

.banner-img-wrapper {
  display: inline-block;
  width: 100%;
  background-size: cover;
  min-height: 600px;

  @media (min-width:480px) and (max-width:1024px) {
    min-height: 700px;
  }

  @media (max-width:480px) {
    min-height: 800px;
  }
}

/* Navigation */

.bg-image-bar {
  background-size: cover;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-bottom: 6rem;
  position: relative;
}

.bg-image-bar-info {
  background-color: #008200;
  color: white;
  width: 50%;
  margin-top: 3rem !important;

  div {
    margin: 3rem;
    padding: 3rem;
  }

  @media screen and (max-width: 1024px) {
    width: 70% !important;

    div {
      margin: 2rem;
      padding: 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100% !important;

    div {
      margin: 1rem;
      padding: 1rem;
    }
  }
}
